import ReactGA from 'react-ga4'

export const datasetRegex = /^[a-z][a-z_0-9]*$/i
export const dataFolderRegex = /^[a-z][a-z_0-9]*$/i
export const mimes = ['application/vnd.ms-excel', 'text/plain', 'text/csv', 'text/tsv', 'csv']

export const fetchConfig = rparams => {
  const {
    match: {
      params: { organizationId, projectId }
    }
  } = rparams

  const conf = {
    orgId: organizationId,
    projectId: projectId
  }

  return conf || null
}

export const getParsedObject = settingObject => {
  try {
    return JSON.parse(settingObject)
  } catch (e) {
    return settingObject
  }
}

export const containsObjectWithKeyValue = (array, key, value) => {
  for (let obj of array) {
    if (obj[key] === value) {
      return true
    }
  }
  return false
}

export const removeDeletedCredientals = userId => {
  let defaultProfileSetting = getParsedObject(window.localStorage.defaultProfileSetting)
  if (defaultProfileSetting !== undefined && defaultProfileSetting !== null) {
    delete defaultProfileSetting[userId]
  }
  window.localStorage.setItem('defaultProfileSetting', JSON.stringify(defaultProfileSetting))
}

export const IsUrlValid = (orgList, currentUrl, windowCache, userId) => {
  if (orgList) {
    const currentOrg = orgList.find(obj => obj.id === currentUrl.orgId)
    if (currentOrg) {
      const currentProject = currentOrg.spaces.find(obj => obj.id === currentUrl.projectId)
      if (currentProject) {
        if (currentUrl.orgId !== windowCache.orgId || windowCache.spaceId !== currentUrl.projectId) {
          const returnedOrg = {
            orgId: currentOrg.id,
            orgIdName: currentOrg.title,
            spaceId: currentProject.id,
            spaceIdName: currentProject.title
          }
          return { type: true, message: returnedOrg }
        }
      } else {
        if (windowCache.spaceId !== 'undefined') {
          removeDeletedCredientals(userId)
          return {
            type: false,
            message: `Oops! It looks like the project you're trying to access couldn't be found. Please verify the your query or contact our assistance.`
          }
        }
      }
    } else {
      removeDeletedCredientals(userId)
      return {
        type: false,
        message: `Oops! It looks like the organization you're trying to access couldn't be found. Please verify the your query or contact our assistance.`
      }
    }
  } else {
    return { type: null, message: 'Reload' }
  }
}

export const IsSpaceValid = (orgList, orgId, spaceId) => {
  if (orgList) {
    const currentOrg = orgList.find(obj => obj.id === orgId)
    if (currentOrg) {
      const currentProject = currentOrg.spaces.find(obj => obj.id === spaceId)
      if (currentProject) {
        const orgObject = {
          orgId: currentOrg.id,
          orgIdName: currentOrg.title,
          spaceId: currentProject.id,
          spaceIdName: currentProject.title
        }
        return { type: 'FOUND', message: orgObject }
      } else {
        return {
          type: 'PNF',
          message: `Oops! It looks like the project you're trying to access couldn't be found. Please verify the your query or contact our assistance.`
        }
      }
    } else {
      return {
        type: 'ONF',
        message: `Oops! It looks like the project you're trying to access couldn't be found. Please verify the your query or contact our assistance.`
      }
    }
  } else {
    return {
      type: 'EMPTY',
      message: `Oops! It looks like the project you're trying to access couldn't be found. Please verify the your query or contact our assistance.`
    }
  }
}

export const fetchInviteUser = (inviteDetails, keycloakToken, orgId, spaceId) => {
  const permissions =
    inviteDetails.special_permissions &&
    inviteDetails.special_permissions.reduce((acc, next) => {
      return [...acc, next.value]
    }, [])

  let invitationDetails = {
    email: inviteDetails.email_Id && inviteDetails.email_Id.trim(),
    orgId: orgId,
    roleName: inviteDetails.user_Role,
    permissions: permissions ? permissions : []
  }
  if (inviteDetails.space_id) invitationDetails.spaceId = spaceId

  const fetchurl = spaceId
    ? `${window.REACT_APP_GATEWAY_API_V3_URL}/org/${orgId}/users/invite?projectId=${spaceId}`
    : `${window.REACT_APP_GATEWAY_API_V3_URL}/org/${orgId}/users/invite`
  return fetch(fetchurl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloakToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(invitationDetails)
  })
    .then(r => r.json().then(data => ({ status: r.status, payload: data })))
    .then(response => {
      return response
    })
    .catch(err => {
      console.log('Error Occured while fetching the user organizations', err)
    })
}

export const splitStringAndGetLastElement = inputString => {
  const words = inputString.split(' ')
  const lastWord = words.pop().trim()
  const restOfString = words.join(' ').trim()
  if (restOfString.length === 0) return [lastWord, '']
  else {
    return [restOfString, lastWord]
  }
}

export const isIntegerValue = inputNumber => {
  return Number.isInteger(inputNumber)
}
export const getBarColor = ({ id, data }) => {
  return data[`${id}Color`] || '#577FBB'
}

export const formatNumber = (number, powNum = 2) => {
  if (!isNaN(parseFloat(number))) {
    const calcDec = Math.pow(10, powNum)
    return Math.trunc(parseFloat(number) * calcDec) / calcDec
  } else {
    return number
  }
}

export const roundNumber = (number, powNum = 2) => {
  if (!isNaN(parseFloat(number))) {
    const calcDec = Math.pow(10, powNum)
    return Math.round(parseFloat(number) * calcDec) / calcDec
  } else {
    return number
  }
}

export const timeout = (ms, promise) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('Timeout'))
    }, ms)
    promise
      .then(response => {
        clearTimeout(timer)
        resolve(response)
      })
      .catch(error => {
        clearTimeout(timer)
        reject(error)
      })
  })
}

export const getErrorMsgsFromCode = new Map([
  [500, 'A backend service returned error'],
  [501, "Sorry about this! The functionality you're looking for is not currently supported by our system"],
  [502, 'A back end service is not reachable at this time, please try again later.'],
  [503, 'A back end service is not available at this time,please try again later.'],
  [504, 'A back end service is taking more time than expected, please try again later.'],
  [
    505,
    'Uh-oh! It looks like the HTTP version you are using is not supported by our server,contact your system admin for assistance'
  ],
  [
    506,
    "We're sorry, but it seems that there is an internal configuration error on our server. This issue is unusual and may require further investigation,Refresh the page or try the operation again after a moment"
  ],
  [
    507,
    "It seems like there's not enough storage space to fulfill your request , please reach out to your admin for a resolution"
  ],
  [401, "Uh-oh! It looks like there's an issue with your access credentials.Please contact your admin for assistance."],
  ['default', window.REACT_APP_ERROR_MESSAGE]
])

export const retryCodes = new Map([
  [500, 'A backend service returned error'],
  [502, 'A back end service is not reachable at this time, please try again later.'],
  [503, 'A back end service is not available at this time,please try again later.'],
  [504, 'A back end service is taking more time than expected, please try again later.'],
  [507, 'Insufficient Storage']
])

export const sUserCredentials = (currentProfileSetting, userId, switchTo, useSwitched) => {
  let lSObject = getParsedObject(currentProfileSetting)

  let orgObject = new Object()
  orgObject[switchTo.orgId] = {
    spaceId: switchTo.spaceId,
    spaceIdName: switchTo.spaceIdName,
    orgIdName: switchTo.orgIdName
  }
  orgObject['activeOrganization'] = switchTo.orgId

  if (lSObject) {
    const uOObject = lSObject[userId]
    if (uOObject) {
      const orgPresent = uOObject[switchTo.orgId]
      if (orgPresent && !useSwitched) {
        lSObject[userId]['activeOrganization'] = switchTo.orgId
      } else {
        lSObject[userId][switchTo.orgId] = {
          spaceId: switchTo.spaceId,
          spaceIdName: switchTo.spaceIdName,
          orgIdName: switchTo.orgIdName
        }
        lSObject[userId]['activeOrganization'] = switchTo.orgId
      }
    } else {
      lSObject[userId] = orgObject
    }
  } else {
    lSObject = { [userId]: orgObject }
  }
  window.localStorage.setItem('defaultProfileSetting', JSON.stringify(lSObject))
}


export const updateUserCredientals = (userId) => {
  const currentProfileSetting = getParsedObject(window.localStorage.defaultProfileSetting)
  try {
    if (currentProfileSetting[userId]) {
      const activeOrgKey = currentProfileSetting[userId].activeOrganization;
      if (currentProfileSetting[userId][activeOrgKey]) {
        currentProfileSetting[userId][activeOrgKey].spaceId = 'undefined';
        currentProfileSetting[userId][activeOrgKey].spaceIdName = 'No Project Yet';
        window.localStorage.setItem('defaultProfileSetting', JSON.stringify(currentProfileSetting))
      }
    }
  } catch (error) { }
}

export const getFaceInValue = listValues => {
  return listValues.reduce((acc, next) => {
    return [...acc, { value: next, label: next.replaceAll('_', ' ').toUpperCase() }]
  }, [])
}

export const functions = [
  {
    key: 'plus',
    type: 'function',
    description: 'Calculates the sum of two values a and b.',
    help: '<div>plus(a,b)</div>'
  },
  {
    key: 'minus',
    type: 'function',
    description: 'Calculates the difference between two values a and b.',
    help: '<div>minus(a,b)</div>'
  },
  {
    key: 'multiply',
    type: 'function',
    description: 'Calculates the product of two values a and b',
    help: '<div>multiply(a,b)</div>'
  },
  {
    key: 'divide',
    type: 'function',
    description: 'ICalculates the quotient of two values a and b.',
    help: '<div>divide(a,b)</div>'
  },
  {
    key: 'intDiv',
    type: 'function',
    description:
      'Performs an integer division of two values a by b, i.e. computes the quotient rounded down to the next smallest integer.',
    help: '<div>intDiv(a,b)</div>'
  },
  {
    key: 'intDivOrZero',
    type: 'function',
    description:
      'Same as intDiv but returns zero when dividing by zero or when dividing a minimal negative number by minus one.',
    help: '<div>intDivOrZero(a,b)</div>'
  },
  {
    key: 'modulo',
    type: 'function',
    description: 'Calculates the remainder of the division of two values a by b.',
    help: '<div>modulo(a,b)</div>'
  },
  {
    key: 'moduloOrZero',
    type: 'function',
    description: 'Same as modulo but returns zero when the divisor is zero',
    help: '<div>moduloOrZero(a,b)</div>'
  },
  {
    key: 'positiveModulo',
    type: 'function',
    description: 'Same as modulo but always returns a non-negative number.',
    help: '<div>positiveModulo(a,b)</div>'
  },
  {
    key: 'gcd',
    type: 'function',
    description: 'Returns the greatest common divisor of two values a and b.',
    help: '<div>gcd(a,b)</div>'
  },
  {
    key: 'lcm',
    type: 'function',
    description: 'Returns the least common multiple of two values a and b.',
    help: '<div>lcm(a,b)</div>'
  },
  {
    key: 'max2',
    type: 'function',
    description: 'Returns the bigger of two values a and b',
    help: '<div>max2(a,b)</div>'
  },
  {
    key: 'min2',
    type: 'function',
    description: 'Returns the smaller of two values a and b',
    help: '<div>min2(a,b)</div>'
  },
  {
    key: 'negate',
    type: 'function',
    description: 'Negates a value a. The result is always signed.',
    help: '<div>negate(a)</div>'
  },
  {
    key: 'abs',
    type: 'function',
    description: 'Calculates the absolute value of a',
    help: '<div>abs(a)</div>'
  }
]

export const cFilterFuncs = [
  {
    functionId: 'TEXT_EQUALS',
    functionName: 'TEXT EQUALS',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_NOT_EQUALS',
    functionName: 'TEXT NOT EQUALS',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_STARTS_WITH',
    functionName: 'TEXT STARTS WITH',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_ENDS_WITH',
    functionName: 'TEXT ENDS WITH',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_CONTAINS',
    functionName: 'TEXT CONTAINS',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_IN',
    functionName: 'TEXT In',
    type: 'String',
    value: [],
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'DATE_EQUALS',
    functionName: 'DATE EQUALS',
    type: 'Date',
    value: '',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_NOT_EQUALS',
    functionName: 'DATE NOT EQUALS',
    value: '',
    type: 'Date',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_LESS_THAN',
    functionName: 'DATE LESS THAN',
    type: 'Date',
    value: '',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_GREATER_THAN',
    functionName: 'DATE GREATER THAN',
    type: 'Date',
    value: '',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_LESS_THAN_EQUAL_TO',
    functionName: 'DATE LESS THAN EQUAL TO',
    type: 'Date',
    value: '',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_GREATER_THAN_EQUAL_TO',
    functionName: 'DATE GREATER THAN EQUAL TO',
    type: 'Date',
    value: '',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_BETWEEN',
    functionName: 'DATE BETWEEN',
    type: 'Date',
    value1: '',
    value2: '',
    column: '',
    isColumn: false
  },
  {
    functionId: 'DATE_IN',
    functionName: 'DATE IN',
    type: 'Date',
    value: [],
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_EQUALS',
    functionName: 'NUMBER EQUALS',
    type: 'Number',
    value: 0,
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_NOT_EQUALS',
    functionName: 'NUMBER NOT EQUALS',
    type: 'Number',
    value: 0,
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_LESS_THAN',
    functionName: 'NUMBER LESS THAN',
    type: 'Number',
    value: 0,
    column: [],
    isColumn: false
  },
  {
    functionId: 'NUMBER_GREATER_THAN',
    functionName: 'NUMBER GREATER THAN',
    type: 'Number',
    value: 0,
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_LESS_THAN_EQUAL_TO',
    functionName: 'NUMBER LESS THAN EQUAL TO',
    type: 'Number',
    value: 0,
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_GREATER_THAN_EQUAL_TO',
    functionName: 'NUMBER GREATER THAN EQUAL TO',
    type: 'Number',
    value: 0,
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_BETWEEN',
    functionName: 'NUMBER BETWEEN',
    type: 'Number',
    value1: 0,
    value2: 0,
    column: '',
    isColumn: false
  },
  {
    functionId: 'NUMBER_IN',
    functionName: 'NUMBER IN',
    type: 'Number',
    value: [],
    column: '',
    isColumn: false
  },
  {
    functionId: 'IS_EMPTY',
    functionName: 'IS EMPTY',
    type: 'String',
    column: ''
  },
  {
    functionId: 'IS_NOT_EMPTY',
    functionName: 'IS NOT EMPTY',
    type: 'String',
    column: ''
  },
  {
    functionId: 'TEXT_NOT_STARTS_WITH',
    functionName: 'TEXT NOT STARTS WITH',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_NOT_ENDS_WITH',
    functionName: 'TEXT NOT ENDS WITH',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_NOT_CONTAINS',
    functionName: 'TEXT NOT CONTAINS',
    type: 'String',
    value: '',
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'TEXT_NOT_IN',
    functionName: 'TEXT NOT IN',
    type: 'String',
    value: [],
    column: '',
    isColumn: false,
    ignoreCase: false
  },
  {
    functionId: 'DATE_NOT_IN',
    functionName: 'DATE NOT IN',
    type: 'Date',
    value: [],
    column: '',
    isColumn: false
  }
]

export const formatBytes = (a, b = 2) => {
  if (!+a) return '0 Bytes'
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024))
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    }`
}

export const getCurrentOrganizationById = (orgList, matchingId) => {
  const currentOrganization =
    orgList &&
    orgList.rolesUser &&
    orgList.rolesUser.organizations.reduce((acc, next) => {
      if (next.id === matchingId) {
        return next
      } else {
        return acc
      }
    }, [])
  return currentOrganization
}

export const countries = [
  {
    value: 'AF',
    label: 'Afghanistan'
  },
  {
    value: 'AL',
    label: 'Albania'
  },
  {
    value: 'DZ',
    label: 'Algeria'
  },
  {
    value: 'AS',
    label: 'American Samoa'
  },
  {
    value: 'AD',
    label: 'Andorra'
  },
  {
    value: 'AO',
    label: 'Angola'
  },
  {
    value: 'AI',
    label: 'Anguilla'
  },
  {
    value: 'AQ',
    label: 'Antarctica'
  },
  {
    value: 'AG',
    label: 'Antigua & Barbuda'
  },
  {
    value: 'AR',
    label: 'Argentina'
  },
  {
    value: 'AM',
    label: 'Armenia'
  },
  {
    value: 'AW',
    label: 'Aruba'
  },
  {
    value: 'AU',
    label: 'Australia'
  },
  {
    value: 'AT',
    label: 'Austria'
  },
  {
    value: 'AZ',
    label: 'Azerbaijan'
  },
  {
    value: 'BS',
    label: 'Bahamas'
  },
  {
    value: 'BH',
    label: 'Bahrain'
  },
  {
    value: 'BD',
    label: 'Bangladesh'
  },
  {
    value: 'BB',
    label: 'Barbados'
  },
  {
    value: 'BY',
    label: 'Belarus'
  },
  {
    value: 'BE',
    label: 'Belgium'
  },
  {
    value: 'BZ',
    label: 'Belize'
  },
  {
    value: 'BJ',
    label: 'Benin'
  },
  {
    value: 'BM',
    label: 'Bermuda'
  },
  {
    value: 'BT',
    label: 'Bhutan'
  },
  {
    value: 'BO',
    label: 'Bolivia'
  },
  {
    value: 'BA',
    label: 'Bosnia & Herzegovina'
  },
  {
    value: 'BW',
    label: 'Botswana'
  },
  {
    value: 'BV',
    label: 'Bouvet Island'
  },
  {
    value: 'BR',
    label: 'Brazil'
  },
  {
    value: 'IO',
    label: 'British Indian Ocean Territory'
  },
  {
    value: 'VG',
    label: 'British Virgin Islands'
  },
  {
    value: 'BN',
    label: 'Brunei'
  },
  {
    value: 'BG',
    label: 'Bulgaria'
  },
  {
    value: 'BF',
    label: 'Burkina Faso'
  },
  {
    value: 'BI',
    label: 'Burundi'
  },
  {
    value: 'KH',
    label: 'Cambodia'
  },
  {
    value: 'CM',
    label: 'Cameroon'
  },
  {
    value: 'CA',
    label: 'Canada'
  },
  {
    value: 'CV',
    label: 'Cape Verde'
  },
  {
    value: 'BQ',
    label: 'Caribbean Netherlands'
  },
  {
    value: 'KY',
    label: 'Cayman Islands'
  },
  {
    value: 'CF',
    label: 'Central African Republic'
  },
  {
    value: 'TD',
    label: 'Chad'
  },
  {
    value: 'CL',
    label: 'Chile'
  },
  {
    value: 'CN',
    label: 'China'
  },
  {
    value: 'CX',
    label: 'Christmas Island'
  },
  {
    value: 'CC',
    label: 'Cocos (Keeling) Islands'
  },
  {
    value: 'CO',
    label: 'Colombia'
  },
  {
    value: 'KM',
    label: 'Comoros'
  },
  {
    value: 'CG',
    label: 'Congo - Brazzaville'
  },
  {
    value: 'CD',
    label: 'Congo - Kinshasa'
  },
  {
    value: 'CK',
    label: 'Cook Islands'
  },
  {
    value: 'CR',
    label: 'Costa Rica'
  },
  {
    value: 'HR',
    label: 'Croatia'
  },
  {
    value: 'CU',
    label: 'Cuba'
  },
  {
    value: 'CW',
    label: 'Curaçao'
  },
  {
    value: 'CY',
    label: 'Cyprus'
  },
  {
    value: 'CZ',
    label: 'Czech Republic'
  },
  {
    value: 'CI',
    label: 'Côte d’Ivoire'
  },
  {
    value: 'DK',
    label: 'Denmark'
  },
  {
    value: 'DJ',
    label: 'Djibouti'
  },
  {
    value: 'DM',
    label: 'Dominica'
  },
  {
    value: 'DO',
    label: 'Dominican Republic'
  },
  {
    value: 'EC',
    label: 'Ecuador'
  },
  {
    value: 'EG',
    label: 'Egypt'
  },
  {
    value: 'SV',
    label: 'El Salvador'
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea'
  },
  {
    value: 'ER',
    label: 'Eritrea'
  },
  {
    value: 'EE',
    label: 'Estonia'
  },
  {
    value: 'SZ',
    label: 'Eswatini'
  },
  {
    value: 'ET',
    label: 'Ethiopia'
  },
  {
    value: 'FK',
    label: 'Falkland Islands'
  },
  {
    value: 'FO',
    label: 'Faroe Islands'
  },
  {
    value: 'FJ',
    label: 'Fiji'
  },
  {
    value: 'FI',
    label: 'Finland'
  },
  {
    value: 'FR',
    label: 'France'
  },
  {
    value: 'GF',
    label: 'French Guiana'
  },
  {
    value: 'PF',
    label: 'French Polynesia'
  },
  {
    value: 'TF',
    label: 'French Southern Territories'
  },
  {
    value: 'GA',
    label: 'Gabon'
  },
  {
    value: 'GM',
    label: 'Gambia'
  },
  {
    value: 'GE',
    label: 'Georgia'
  },
  {
    value: 'DE',
    label: 'Germany'
  },
  {
    value: 'GH',
    label: 'Ghana'
  },
  {
    value: 'GI',
    label: 'Gibraltar'
  },
  {
    value: 'GR',
    label: 'Greece'
  },
  {
    value: 'GL',
    label: 'Greenland'
  },
  {
    value: 'GD',
    label: 'Grenada'
  },
  {
    value: 'GP',
    label: 'Guadeloupe'
  },
  {
    value: 'GU',
    label: 'Guam'
  },
  {
    value: 'GT',
    label: 'Guatemala'
  },
  {
    value: 'GG',
    label: 'Guernsey'
  },
  {
    value: 'GN',
    label: 'Guinea'
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau'
  },
  {
    value: 'GY',
    label: 'Guyana'
  },
  {
    value: 'HT',
    label: 'Haiti'
  },
  {
    value: 'HM',
    label: 'Heard & McDonald Islands'
  },
  {
    value: 'HN',
    label: 'Honduras'
  },
  {
    value: 'HK',
    label: 'Hong Kong SAR China'
  },
  {
    value: 'HU',
    label: 'Hungary'
  },
  {
    value: 'IS',
    label: 'Iceland'
  },
  {
    value: 'IN',
    label: 'India'
  },
  {
    value: 'ID',
    label: 'Indonesia'
  },
  {
    value: 'IR',
    label: 'Iran'
  },
  {
    value: 'IQ',
    label: 'Iraq'
  },
  {
    value: 'IE',
    label: 'Ireland'
  },
  {
    value: 'IM',
    label: 'Isle of Man'
  },
  {
    value: 'IL',
    label: 'Israel'
  },
  {
    value: 'IT',
    label: 'Italy'
  },
  {
    value: 'JM',
    label: 'Jamaica'
  },
  {
    value: 'JP',
    label: 'Japan'
  },
  {
    value: 'JE',
    label: 'Jersey'
  },
  {
    value: 'JO',
    label: 'Jordan'
  },
  {
    value: 'KZ',
    label: 'Kazakhstan'
  },
  {
    value: 'KE',
    label: 'Kenya'
  },
  {
    value: 'KI',
    label: 'Kiribati'
  },
  {
    value: 'KW',
    label: 'Kuwait'
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan'
  },
  {
    value: 'LA',
    label: 'Laos'
  },
  {
    value: 'LV',
    label: 'Latvia'
  },
  {
    value: 'LB',
    label: 'Lebanon'
  },
  {
    value: 'LS',
    label: 'Lesotho'
  },
  {
    value: 'LR',
    label: 'Liberia'
  },
  {
    value: 'LY',
    label: 'Libya'
  },
  {
    value: 'LI',
    label: 'Liechtenstein'
  },
  {
    value: 'LT',
    label: 'Lithuania'
  },
  {
    value: 'LU',
    label: 'Luxembourg'
  },
  {
    value: 'MO',
    label: 'Macao SAR China'
  },
  {
    value: 'MG',
    label: 'Madagascar'
  },
  {
    value: 'MW',
    label: 'Malawi'
  },
  {
    value: 'MY',
    label: 'Malaysia'
  },
  {
    value: 'MV',
    label: 'Maldives'
  },
  {
    value: 'ML',
    label: 'Mali'
  },
  {
    value: 'MT',
    label: 'Malta'
  },
  {
    value: 'MH',
    label: 'Marshall Islands'
  },
  {
    value: 'MQ',
    label: 'Martinique'
  },
  {
    value: 'MR',
    label: 'Mauritania'
  },
  {
    value: 'MU',
    label: 'Mauritius'
  },
  {
    value: 'YT',
    label: 'Mayotte'
  },
  {
    value: 'MX',
    label: 'Mexico'
  },
  {
    value: 'FM',
    label: 'Micronesia'
  },
  {
    value: 'MD',
    label: 'Moldova'
  },
  {
    value: 'MC',
    label: 'Monaco'
  },
  {
    value: 'MN',
    label: 'Mongolia'
  },
  {
    value: 'ME',
    label: 'Montenegro'
  },
  {
    value: 'MS',
    label: 'Montserrat'
  },
  {
    value: 'MA',
    label: 'Morocco'
  },
  {
    value: 'MZ',
    label: 'Mozambique'
  },
  {
    value: 'MM',
    label: 'Myanmar (Burma)'
  },
  {
    value: 'NA',
    label: 'Namibia'
  },
  {
    value: 'NR',
    label: 'Nauru'
  },
  {
    value: 'NP',
    label: 'Nepal'
  },
  {
    value: 'NL',
    label: 'Netherlands'
  },
  {
    value: 'NC',
    label: 'New Caledonia'
  },
  {
    value: 'NZ',
    label: 'New Zealand'
  },
  {
    value: 'NI',
    label: 'Nicaragua'
  },
  {
    value: 'NE',
    label: 'Niger'
  },
  {
    value: 'NG',
    label: 'Nigeria'
  },
  {
    value: 'NU',
    label: 'Niue'
  },
  {
    value: 'NF',
    label: 'Norfolk Island'
  },
  {
    value: 'KP',
    label: 'North Korea'
  },
  {
    value: 'MK',
    label: 'North Macedonia'
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'NO',
    label: 'Norway'
  },
  {
    value: 'OM',
    label: 'Oman'
  },
  {
    value: 'PK',
    label: 'Pakistan'
  },
  {
    value: 'PW',
    label: 'Palau'
  },
  {
    value: 'PS',
    label: 'Palestinian Territories'
  },
  {
    value: 'PA',
    label: 'Panama'
  },
  {
    value: 'PG',
    label: 'Papua New Guinea'
  },
  {
    value: 'PY',
    label: 'Paraguay'
  },
  {
    value: 'PE',
    label: 'Peru'
  },
  {
    value: 'PH',
    label: 'Philippines'
  },
  {
    value: 'PN',
    label: 'Pitcairn Islands'
  },
  {
    value: 'PL',
    label: 'Poland'
  },
  {
    value: 'PT',
    label: 'Portugal'
  },
  {
    value: 'PR',
    label: 'Puerto Rico'
  },
  {
    value: 'QA',
    label: 'Qatar'
  },
  {
    value: 'RO',
    label: 'Romania'
  },
  {
    value: 'RU',
    label: 'Russia'
  },
  {
    value: 'RW',
    label: 'Rwanda'
  },
  {
    value: 'RE',
    label: 'Réunion'
  },
  {
    value: 'WS',
    label: 'Samoa'
  },
  {
    value: 'SM',
    label: 'San Marino'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia'
  },
  {
    value: 'SN',
    label: 'Senegal'
  },
  {
    value: 'RS',
    label: 'Serbia'
  },
  {
    value: 'SC',
    label: 'Seychelles'
  },
  {
    value: 'SL',
    label: 'Sierra Leone'
  },
  {
    value: 'SG',
    label: 'Singapore'
  },
  {
    value: 'SX',
    label: 'Sint Maarten'
  },
  {
    value: 'SK',
    label: 'Slovakia'
  },
  {
    value: 'SI',
    label: 'Slovenia'
  },
  {
    value: 'SB',
    label: 'Solomon Islands'
  },
  {
    value: 'SO',
    label: 'Somalia'
  },
  {
    value: 'ZA',
    label: 'South Africa'
  },
  {
    value: 'GS',
    label: 'South Georgia & South Sandwich Islands'
  },
  {
    value: 'KR',
    label: 'South Korea'
  },
  {
    value: 'SS',
    label: 'South Sudan'
  },
  {
    value: 'ES',
    label: 'Spain'
  },
  {
    value: 'LK',
    label: 'Sri Lanka'
  },
  {
    value: 'BL',
    label: 'St. Barthélemy'
  },
  {
    value: 'SH',
    label: 'St. Helena'
  },
  {
    value: 'KN',
    label: 'St. Kitts & Nevis'
  },
  {
    value: 'LC',
    label: 'St. Lucia'
  },
  {
    value: 'MF',
    label: 'St. Martin'
  },
  {
    value: 'PM',
    label: 'St. Pierre & Miquelon'
  },
  {
    value: 'VC',
    label: 'St. Vincent & Grenadines'
  },
  {
    value: 'SD',
    label: 'Sudan'
  },
  {
    value: 'SR',
    label: 'Suriname'
  },
  {
    value: 'SJ',
    label: 'Svalbard & Jan Mayen'
  },
  {
    value: 'SE',
    label: 'Sweden'
  },
  {
    value: 'CH',
    label: 'Switzerland'
  },
  {
    value: 'SY',
    label: 'Syria'
  },
  {
    value: 'ST',
    label: 'São Tomé & Príncipe'
  },
  {
    value: 'TW',
    label: 'Taiwan'
  },
  {
    value: 'TJ',
    label: 'Tajikistan'
  },
  {
    value: 'TZ',
    label: 'Tanzania'
  },
  {
    value: 'TH',
    label: 'Thailand'
  },
  {
    value: 'TL',
    label: 'Timor-Leste'
  },
  {
    value: 'TG',
    label: 'Togo'
  },
  {
    value: 'TK',
    label: 'Tokelau'
  },
  {
    value: 'TO',
    label: 'Tonga'
  },
  {
    value: 'TT',
    label: 'Trinidad & Tobago'
  },
  {
    value: 'TN',
    label: 'Tunisia'
  },
  {
    value: 'TR',
    label: 'Turkey'
  },
  {
    value: 'TM',
    label: 'Turkmenistan'
  },
  {
    value: 'TC',
    label: 'Turks & Caicos Islands'
  },
  {
    value: 'TV',
    label: 'Tuvalu'
  },
  {
    value: 'UM',
    label: 'U.S. Outlying Islands'
  },
  {
    value: 'VI',
    label: 'U.S. Virgin Islands'
  },
  {
    value: 'UG',
    label: 'Uganda'
  },
  {
    value: 'UA',
    label: 'Ukraine'
  },
  {
    value: 'AE',
    label: 'United Arab Emirates'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'UY',
    label: 'Uruguay'
  },
  {
    value: 'UZ',
    label: 'Uzbekistan'
  },
  {
    value: 'VU',
    label: 'Vanuatu'
  },
  {
    value: 'VA',
    label: 'Vatican City'
  },
  {
    value: 'VE',
    label: 'Venezuela'
  },
  {
    value: 'VN',
    label: 'Vietnam'
  },
  {
    value: 'WF',
    label: 'Wallis & Futuna'
  },
  {
    value: 'EH',
    label: 'Western Sahara'
  },
  {
    value: 'YE',
    label: 'Yemen'
  },
  {
    value: 'ZM',
    label: 'Zambia'
  },
  {
    value: 'ZW',
    label: 'Zimbabwe'
  },
  {
    value: 'AX',
    label: 'Åland Islands'
  }
]
const currency_symbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫' // Vietnamese Dong
}
export const getCurrencySymbol = currencyName => {
  if (currency_symbols[currencyName.toUpperCase()] === undefined) {
    return `${currencyName} `
  } else {
    return currency_symbols[currencyName.toUpperCase()]
  }
}

export const manageProjectStatus = (receivedCollapsable, orgId, spaceId, objectiveId, status) => {
  let currentCollapsable = getParsedObject(receivedCollapsable)

  let newSpaceEntry = new Object()
  newSpaceEntry[spaceId] = {
    [objectiveId]: status
  }

  if (currentCollapsable) {
    const isOrgEntry = currentCollapsable[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        currentCollapsable[orgId][spaceId] = { ...currentCollapsable[orgId][spaceId], [objectiveId]: status }
      } else {
        currentCollapsable[orgId][spaceId] = { [objectiveId]: status }
      }
    } else {
      currentCollapsable[orgId] = newSpaceEntry
    }
  } else {
    currentCollapsable = { [orgId]: newSpaceEntry }
  }
  window.localStorage.setItem('profileCollapse', JSON.stringify(currentCollapsable))
}

export const getManagedProjectStatus = (orgId, spaceId, objectiveId) => {
  let currentCollapsable = getParsedObject(window.localStorage.profileCollapse)

  if (currentCollapsable) {
    const isOrgEntry = currentCollapsable[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        return currentCollapsable[orgId][spaceId][objectiveId]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const removeDuplicatesByKey = (array, key) => {
  const uniqueObjects = []
  const keysSet = new Set()
  for (const obj of array) {
    if (!keysSet.has(obj[key])) {
      keysSet.add(obj[key])
      uniqueObjects.push(obj)
    }
  }
  return uniqueObjects
}

export const transformDatasetResponse = response => {
  const dataset = response['dataset']
  const dsItems = response['datasetItems']

  var datasetItems = []
  dsItems.forEach(item => {
    var dsItemData = DsItemData(
      item.datasetItemId,
      item.created,
      item.updated,
      item.name,
      item.datasetItemType,
      item.numRecords,
      item.datasetItemSize,
      item.datasetId,
      item.datasetItemStatus,
      dataset.datasetType
    )
    datasetItems.push(dsItemData)
  })

  if (dataset.datasetFolder) {
    return {
      docs: datasetItems,
      currentFolderId: dataset.datasetId,
      currentFolderName: dataset.name,
      ...(dataset.lock && { lock: dataset.lock }),
      parentFolderId: dataset.hierarchy[0].id,
      parentFolderName: dataset.hierarchy[0].name
    }
  } else {
    return {
      docs: datasetItems,
      currentFolderId: dataset.datasetId,
      currentFolderName: dataset.name,
      ...(dataset.lock && { lock: dataset.lock })
    }
  }
}

export const transformDatasetListResponse = response => {
  const folders = response && response.payload && response.payload.folders ? response.payload.folders : []
  const datasets = response && response.payload && response.payload.datasets ? response.payload.datasets : []

  if (folders) {
    folders.forEach(folder => {
      folder['id'] = folder.dataFolderId
      folder['kind'] = 'DATAFOLDER'
    })
  }

  let folderHiearachy = null

  if (response && response.payload && response.payload.parent) {
    const paths = response.payload.parent
    folderHiearachy = { currentFolderId: paths.dataFolderId, currentFolderName: paths.name }
    if (paths.parent) {
      folderHiearachy = {
        ...folderHiearachy,
        parentFolderId: paths.parent.dataFolderId,
        parentFolderName: paths.parent.name
      }
    }
  }

  if (datasets) {
    datasets.forEach(dataset => {
      dataset['id'] = dataset.datasetId
      dataset['kind'] = 'DATASET'
    })
  }

  return { docs: [...folders, ...datasets], ...(folderHiearachy && folderHiearachy) }
}

const DsItemData = (
  id,
  created,
  updated,
  name,
  datasetItemType,
  numRecords,
  datasetItemSize,
  datasetId,
  datasetItemStatus,
  datasetType
) => {
  return {
    id: id,
    created: created,
    updated: updated,
    name: name,
    datasetItemType: datasetItemType,
    numRecords: numRecords,
    datasetItemSize: datasetItemSize,
    datasetId: datasetId,
    datasetItemStatus: datasetItemStatus,
    datasetType: datasetType,
    kind: 'DATAITEM'
  }
}

export const allowedFeatures = isAdvanced => {
  return [
    {
      name: 'datasets',
      link: '/datasets',
      title: 'DataLab',
      external: false,
      icon: 'fa fa-database'
    },
    {
      name: 'apps',
      link: '/apps',
      title: 'Apps',
      external: false,
      icon: 'fa fa-th'
    },
    {
      name: 'studio',
      link: window.REACT_APP_GATEWAY_STUDIO_URL,
      title: 'Studio',
      external: true,
      icon: 'fa dt-icon-studio-1'
    },
    {
      name: 'emlyViz',
      link: '/emlyViz',
      title: 'Vizard',
      external: false,
      icon: 'fa emly-icn'
    },
    isAdvanced && {
      name: 'emlyNoteBook',
      link: '/emlyNoteBook',
      title: 'Notebook',
      external: false,
      icon: 'fa emly-icn'
    }
  ]
}

export const converEpocDateToReadableData = t => {
  //takes epoc date and converts it to readable date format
  var myDate = new Date(t * 1000)

  return myDate.toLocaleString()
}

export const groupProjects = (array, key, customGroupName) => {
  return array.reduce((acc, obj) => {
    const keyValue = obj[key]
    const groupName = keyValue !== undefined ? keyValue : customGroupName
    acc[groupName] = acc[groupName] || []
    acc[groupName].push(obj)
    return acc
  }, {})
}

export const getObjectByKey = (array, key, value) => {
  return array.find(item => item[key] === value)
}

export const makeJsons = array => {
  try {
    return JSON.parse(array.replace(/'/g, '"'))
  } catch (e) {
    return array
  }
}

export const manageOrgChoice = (receivedLearnFlowChoice, orgId, objectiveId, value) => {
  let OrgChoice = getParsedObject(receivedLearnFlowChoice)

  const item = { [objectiveId]: value }

  if (OrgChoice) {
    const isOrgEntry = OrgChoice[orgId]
    if (isOrgEntry) {
      OrgChoice[orgId] = { ...OrgChoice[orgId], [objectiveId]: value }
    } else {
      OrgChoice[orgId] = item
    }
  } else {
    OrgChoice = { [orgId]: item }
  }
  window.localStorage.setItem('OrgChoice', JSON.stringify(OrgChoice))
}

export const getOrgChoices = (orgId, objective) => {
  let OrgChoice = getParsedObject(window.localStorage.OrgChoice)

  if (OrgChoice) {
    const isOrgEntry = OrgChoice[orgId]
    if (isOrgEntry) {
      return isOrgEntry[objective]
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const getShortDate = startDate => {
  const date = new Date(startDate)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }

  return date.toLocaleDateString('en-US', options)
}

export const initializeReactGoogle = userInfo => {
  const trackingId = window.REACT_GA_CONFIG ? window.REACT_GA_CONFIG['trackingId'] : 'G-G57WZN6KNZ'
  const testMode = window.REACT_GA_CONFIG ? window.REACT_GA_CONFIG['testMode'] : true

  if (userInfo.email) {
    ReactGA.set({
      userName: userInfo.email
    })
  }

  ReactGA.initialize([
    {
      trackingId: trackingId,
      gaOptions: {
        testMode: testMode,
        name: userInfo.email ? userInfo.email : 'USER',
        params: JSON.stringify({
          userEmail: userInfo.email ? userInfo.email : 'USER',
          userRole: userInfo.role ? userInfo.role : 'DEFAULT',
          userPlan: userInfo.plan ? userInfo.plan : 'DEFAULT'
        })
      }
    }
  ])
}

export const notifyGoogle = eventName => {
  ReactGA.event({
    category: eventName,
    action: eventName
  })
}

export const uberUpStates = ['DEPLOY_SUCCESS']

export const uberDownStates = ['NOT_FOUND', 'DEPLOY_FAILED', 'DELETED', 'IN_VALID', 'NONE']

export const uberRunningStates = ['REQUESTING', 'DEPLOYING', 'SUBMITTED', 'INITIALISED', 'VERIFYING']

export const genAiStartedStates = ['DEPLOYING', 'SUBMITTED', 'INITIALISED', 'VERIFYING', 'IN_PROGRESS']

export const deploymentStates = ['NONE', 'DEPLOYING', 'DEPLOY_SUCCESS', 'DEPLOY_FAILED', 'DELETED', 'IN_VALID', 'SUBMITTED', 'INITIALISED', 'VERIFYING', 'COMPLETED']

export const jobRunningStates = ["QUEUED", "STARTED", "IN_PROGRESS", "RUNNING", "READY"]

export const blockedNotificationPaths = cUser => [
  `/${cUser.orgId}/${cUser.spaceId}/datasets`,
  `/${cUser.orgId}/${cUser.spaceId}/emlyViz`
]

export const UserRolePrecidence = new Map([
  ['owner', { grouping: true }],
  ['org_admin', { grouping: true }],
  ['project_admin', { grouping: true }],
  ['member', { grouping: false }],
  ['observer', { grouping: false }],
  ['default', { grouping: false }]
])

export const JobStatusLevel = new Map([
  ['QUEUED', 1],
  ['STARTED', 2],
  ['IN_PROGRESS', 3],
  ['RUNNING', 4],
  ['COMPLETED', 5]
])

export const getEngagementScore = (projectObjectives) => {
  try {
    const score = projectObjectives.reduce((acc, next) => {
      if (next.status === 'Completed') {
        return [...acc, next]
      } else {
        return acc
      }
    }, [])

    let total_steps = projectObjectives && projectObjectives.length
    let completed_steps = score.length

    let engangement_score = (completed_steps / total_steps) * 100

    return Math.ceil(engangement_score)
  } catch (error) {
    return 0
  }
}

export const botDefaultSettings = () => {
  return {
    title: 'EmlyLabs',
    subtitle: 'Your smart guide',
    welcomeMessage: 'Welcome To Emly Labs Asstiant',
    openOnLoad: true,    
    launcherBackground :'#000000',
    headerBackground: '#5ccbb5',
    headerForegroundColor: '#ffffff',
    containerBackground: '#d3d3d3',
    botMessageBackground: '#ffffff',
    botMessageForegroundColor: '#000000',
    userMessageBackground: '#6aa84f',
    userMessageForegroundColor: '#ffffff',
    logo: 'https://storage.googleapis.com/17d84a62bfae0c05e940e9d8668ed0029429c724/public/chat-widget/emly-labs-avataar.png',    
    openIcon:'https://storage.googleapis.com/17d84a62bfae0c05e940e9d8668ed0029429c724/public/chat-widget/images/launcher_open_icon.png',
    closeIcon:'https://storage.googleapis.com/17d84a62bfae0c05e940e9d8668ed0029429c724/public/chat-widget/images/launcher_close_icon.png',
    asApp : false,
    cardFooter : false,
    cardContent : true,
    showGraphic :true,
    rightMenu :true,
    showMinMax : true,
    appId: "",
    whatsAppLink: "",
    whatsAppMessage:"Hi, I would like to know more about your services.",
    env: window.NODE_ENV,
    launcherPoistion:'default',
    starterMessages:"",
    showClose:true,
    supportEmail:"",
    copyParentTheme:true,
    cardSize:"small",
    captureUserInfoAtStart:false,
    openLinkInSameTab:false,
    isIconWithLabel:false,
    inputPlaceholder:"message emlylabs..."
  }
}

export const pushBotConfig = (orgId, spaceId, appId, Json) => {
  let currentCollapsable = getParsedObject(window.localStorage.EmlyLabsBotAppConf)

  let newSpaceEntry = new Object()
  newSpaceEntry[spaceId] = {
    [appId]: Json
  }

  if (currentCollapsable) {
    const isOrgEntry = currentCollapsable[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        currentCollapsable[orgId][spaceId] = { ...currentCollapsable[orgId][spaceId], [appId]: Json }
      } else {
        currentCollapsable[orgId][spaceId] = { [appId]: Json }
      }
    } else {
      currentCollapsable[orgId] = newSpaceEntry
    }
  } else {
    currentCollapsable = { [orgId]: newSpaceEntry }
  }
  window.localStorage.setItem('EmlyLabsBotAppConf', JSON.stringify(currentCollapsable))
}

export const getBotConfig = (orgId, spaceId, appId) => {
  let currentCollapsable = getParsedObject(window.localStorage.EmlyLabsBotAppConf)

  if (currentCollapsable) {
    const isOrgEntry = currentCollapsable[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        return currentCollapsable[orgId][spaceId][appId]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const datasetKeys = ['datasetId',"INPUT_DATASET_ID"]

export const convertStringInToArray = (inputText) => {  
  if(!Array.isArray(inputText)){
    const arrayFromText = inputText.split('\n'); 
    const filteredArray = arrayFromText.filter(item => item.trim() !== '');
    return filteredArray
  }
  if(Array.isArray(inputText)){
    return inputText
  }
}

export const GetUrls = (jobId,type) =>  new Map([
  ['ACAD1', { baseUrl: `https://${jobId}-genai.acad1.emlylabs.com/emly/api/${type}` }],
  ['PRE', { baseUrl: `https://${jobId}-genai.pre.datoin.com/emly/api/${type}` }],
  ['PROD', { baseUrl: `https://${jobId}-genai.app.emlylabs.com/emly/api/${type}` }],
  ['DEFAULT', { baseUrl: `https://${jobId}-genai.app.emlylabs.com/emly/api/${type}` }]    
])

export const appStoreAppTypes = ["crawler","genAI","timeseries","classifier","regression"]

export const doRetryCodes = [
  500,
  502,
  503,
  504,
  507
]

export const saveToLocalStore = (orgId, spaceId, keyId, Json,identifier) => {
  let sEntry = getParsedObject(window.localStorage[identifier])

  let newSpaceEntry = new Object()
  newSpaceEntry[spaceId] = {
    [keyId]: Json
  }

  if (sEntry) {
    const isOrgEntry = sEntry[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        sEntry[orgId][spaceId] = { ...sEntry[orgId][spaceId], [keyId]: Json }
      } else {
        sEntry[orgId][spaceId] = { [keyId]: Json }
      }
    } else {
      sEntry[orgId] = newSpaceEntry
    }
  } else {
    sEntry = { [orgId]: newSpaceEntry }
  }
  window.localStorage.setItem([identifier], JSON.stringify(sEntry))
}

export const getFromLocalStore = (orgId, spaceId, keyId,identifier) => {
  let sEntry = getParsedObject(window.localStorage[identifier])

  if (sEntry) {
    const isOrgEntry = sEntry[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        return sEntry[orgId][spaceId][keyId]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const deleteFromLocalStore = (orgId, spaceId, keyId,identifier) => {
  let sEntry = getParsedObject(window.localStorage[identifier])
  if (sEntry) {
    const isOrgEntry = sEntry[orgId]
    if (isOrgEntry) {
      const isSpaceEntry = isOrgEntry[spaceId]
      if (isSpaceEntry) {
        delete sEntry[orgId][spaceId][keyId]
      }
    }
  }
}