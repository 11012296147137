import React, { useState } from 'react'
import Select from 'react-select'

const ProjectUtils = props => {
  const [groupId, setGroupId] = useState(null)

  const onGroupChange = value => {
    setGroupId(value)
    props.setGroupId(value)
  }

  return (
    <>
      <div className="items">
        <Select
          options={props.userGroups}
          isMulti={false}
          disabled={false}
          value={groupId}
          onChange={onGroupChange}
          placeholder="Select a group"
        />
        {groupId &&
        groupId['groupInfo'] &&
        groupId['groupInfo']['numberOfUsers'] !== undefined &&
        groupId['groupInfo']['numberOfUsers'] !== null ? (
          <div className="project-group-help">No. of Users: {groupId.groupInfo.numberOfUsers}</div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default ProjectUtils
