import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { loadDatset, saveDataset } from '../../redux/DatasettReducer'
import { addTaskWindowTroughtApi } from '../../redux/FrameWorkReducer'
import { emptyNotification } from '../../redux/notificationAction'
import { pushProjects } from '../../redux/ProjectReducer'
import PopUp from '../PopUP'
import '../ReusableCode/emlyMain.css'
import { LoadingFullScreen } from '../utils'
import { sUserCredentials } from '../utils/commonUtilityFunctions'
import { getCurrentContextuserIdDetails } from './AccountUtility'
import TopLayerInternal from './TopLayer'
import './topLayerComponent.css'

class TopLayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGif: false,
      prompt: false,
      swithedOrg: {},
      isLoading: false,
      retryCount: 0
    }
  }

  showSwitch = it => {
    this.setState({ prompt: true, swithedOrg: it })
  }

  handleChange = selectType => {
    if (selectType === 'CLOSE') {
      this.setState({ prompt: false })
      return
    }
    const switchTo = {
      orgId: this.state.swithedOrg.id,
      orgIdName: this.state.swithedOrg.title,
      spaceId:
        this.state.swithedOrg.spaces[0] && this.state.swithedOrg.spaces[0].id
          ? this.state.swithedOrg.spaces[0].id
          : 'undefined',
      spaceIdName:
        this.state.swithedOrg.spaces[0] && this.state.swithedOrg.spaces[0].name
          ? this.state.swithedOrg.spaces[0].name
          : 'No Project Yet'
    }
    sUserCredentials(
      window.localStorage.defaultProfileSetting,
      this.props.myUserAccountInfo.current_logged_user,
      switchTo,
      false
    )
    this.props.dispatch(saveDataset([]))
    this.props.dispatch(pushProjects(null))
    this.props.dispatch(loadDatset(true))
    this.props.dispatch(addTaskWindowTroughtApi([]))
    this.props.dispatch(emptyNotification())
    let currentContextLoggedUser = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    this.fetchMyAccountInfoAndfetchMyProfile(currentContextLoggedUser.orgId, currentContextLoggedUser.spaceId)
    this.updateCurrentContext(currentContextLoggedUser)
    this.setState({ prompt: false })
  }

  updateCurrentContext = value => {
    const api = this.props.api
    if (api == null) return
    const action = api.getCurrenttContextRSAA(value)
    this.props.dispatch(action)
  }

  navigateTo = path => {
    const { history } = this.props
    history.push(path)
  }

  fetchMyAccountInfoAndfetchMyProfile = (orgId, spaceId) => {
    this.setState({ isLoading: true })
    const api = this.props.api
    if (api == null) return
    const action = api.createFetchUserAccountInfoSilently()

    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        this.subscriptionUsage()
        const action = api.createFetchMyProfileRSAA(orgId, spaceId)
        Promise.resolve(this.props.dispatch(action)).then(result => {
          const hasErrorinProfile = response.type.toLowerCase().endsWith('failure')
          if (!hasErrorinProfile) {
            this.subscriptionCheck()
            this.fetchPermissionMatrix(orgId, spaceId)
            this.navigateTo(`/organizations`)
            this.fetchUberDeployment(orgId, spaceId)
            this.setState({ isLoading: false })
          } else {
            window.location = '/'
          }
        })
      } else {
        window.location = '/'
      }
    })
  }

  subscriptionUsage() {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchMySubscriptionPlanUsageRSAA(current_logged_user)
    this.props.dispatch(action)
  }

  subscriptionCheck() {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.getOrgSubscriptionCheckRSAA(current_logged_user)
    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        if (
          response.payload &&
          response.payload.subscriptionStatus &&
          response.payload.subscriptionStatus === 'PAYMENT_EXPIRED'
        ) {
          window.location = '/'
        } else if (
          response.payload &&
          response.payload.subscriptionStatus &&
          response.payload.subscriptionStatus === 'BUY_PLAN'
        ) {
          window.location = '/'
        }
      } else {
        if (this.state.retryCount < 3) {
          this.setState({ retryCount: this.state.retryCount + 1 })
          setTimeout(() => {
            this.subscriptionCheck()
          }, [1500])
        } else {
          console.error('Max retry attempts reached')
        }
      }
    })
  }

  fetchUberDeployment = (orgId, spaceId) => {
    const api = this.props.api
    if (api === null) return
    const action = api.createFetchUberDeploymentRSAA(orgId, false, spaceId)
    this.props.dispatch(action)
  }

  fetchPermissionMatrix = (orgId, projectId) => {
    let credientals = {
      orgId: orgId,
      spaceId: projectId
    }
    const api = this.props.api
    if (api == null) return
    const action = api.fetchPermissionMatrixObjectRSAA(credientals)
    this.props.dispatch(action)
  }

  render() {
    const tasks =
      this.props.taskWindow !== undefined &&
      this.props.taskWindow.length > 0 &&
      filterLatestObjects(this.props.taskWindow.filter(i => isJson(i).time !== undefined))
    return (
      <>
        {this.state.isLoading && <LoadingFullScreen msg={'We are preparing to switch Your account'} />}

        {this.state.prompt && (
          <PopUp
            width={'500px'}
            loadingCancel={true}
            buttonLabel={'Yes'}
            bottonLabelCencel={'No'}
            showScroll={false}
            onCancel={() => {
              this.handleChange('CLOSE')
            }}
            onExecution={() => {
              this.handleChange()
            }}
          >
            <div className="em-row organization-checkout">
              <p className="c-label">Are you sure you want to switch to</p>
              <p className="c-org">{this.state.swithedOrg && this.state.swithedOrg.title}?</p>
            </div>
          </PopUp>
        )}
        <TopLayerInternal
          showGif={this.state.showGif}
          showSwitch={this.showSwitch}
          displayName={this.props.displayName}
          shutdown={this.props.shutdown}
          theme={this.props.themes}
          tasks={tasks}
          dispatch={this.props.dispatch}
          isOrg={this.props.isOrg}
          userProfilePersisted={this.props.userProfilePersisted}
          datasets={this.props.datasets}
          projects={this.props.projects}
          myProfile={this.props.myProfile}
          myAppsSection={this.props.myAppsSection}
          handleFilterFormChange={this.props.handleFilterFormChange}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  api: state.api,
  myProfile: state.myProfile,
  taskWindow: state.emlyFrameWork.taskWindow,
  myUserAccountInfo: state.myUserAccountInfo,
  userProfilePersisted: state.userProfilePersisted,
  spec_toggle: state.emlyFrameWork.spec_toggle
})

export default withRouter(connect(mapStateToProps)(TopLayer))

export const getColor = key => {
  switch (key) {
    case 'ABORTED':
      return 'red'
    case 'FAILED':
      return 'red'
    case 'RUNNING':
      return 'blue'
    case 'QUEUED':
      return 'blue'
    case 'COMPLETED':
      return 'green'
    default:
      return 'gray'
  }
}

export const isJson = array => {
  try {
    return JSON.parse(array.replace(/'/g, '"'))
  } catch (e) {
    return array
  }
}

export function filterLatestObjects(objects) {
  const idMap = new Map()

  for (const obj of objects) {
    if (!idMap.has(obj.id) || obj.time > idMap.get(obj.id).time) {
      idMap.set(obj.id, obj)
    }
  }

  return Array.from(idMap.values())
}
