import { WEBSOCKET_MESSAGE } from '@giantmachines/redux-websocket'
import API from '../Api.js'
import actionTypes from './actionTypes.js'

export function apiError(state = { message: null }, action) {
  if (action.type.endsWith('_REQUEST') || action.type.endsWith('_SUCCESS')) {
    return { message: null }
  }
  if (action.type.endsWith('_FAILURE')) {
    return { ...action.payload.response }
  }
  return state
}

export function ws(state = {}, action) {
  if (action.type === WEBSOCKET_MESSAGE) {
    return { ...action.payload }
  }
  return state
}

export function api(state = null, action) {
  if (action.type === actionTypes.INIT_API_REQUEST) {
    return new API({
      apiRootUrl: `${window.REACT_APP_GATEWAY_API_URL}`,
      apiV2RootUrl: `${window.REACT_APP_GATEWAY_API_V2_URL}`,
      apiV3RootUrl: `${window.REACT_APP_GATEWAY_API_V3_URL}`,
      apiV4RootUrl: `${window.REACT_APP_GATEWAY_API_V4_URL}`,
      fileRootUrl: `${window.REACT_APP_GATEWAY_FILE_URL}`,
      wssUrl: `${window.REACT_APP_GATEWAY_WEB_SOCKET_URL}`
    })
  }
  return state
}

export function myApps(
  state = {
    isLoading: true,
    isLoadingNoScroll: true,
    error: null,
    jobs: [],
    searchInfo: {
      start: 0,
      max: 10,
      query: null,
      jobRunType: null,
      total: 0,
      nextStart: 0,
      sort: null,
      sortOrder: null,
      statusFilter: []
    }
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_MY_APPS_REQUEST:
      return { ...state, isLoading: action.meta.append ? false : true }
    case actionTypes.FETCH_MY_APPS_SUCCESS: {
      if (action.meta.append) {
        let previousDocs = state.jobs
        return Object.assign(action.payload, {
          isLoading: false,
          error: null,
          jobs: previousDocs.concat(action.payload.jobs)
        })
      }
      return Object.assign(action.payload, { isLoading: false, error: null })
    }
    case actionTypes.FETCH_MY_APPS_FAILURE:
      return { ...state, isLoading: action.meta.append ? false : true, error: action.payload }
    case actionTypes.DELETE_APP_SUCCESS: {
      var updatedDocs = state.jobs.filter(app => app.id !== action.meta.id)
      return { ...state, jobs: updatedDocs }
    }
  }
  return state
}

export function myProfile(state = { isLoading: false, allowedFeatures: [], undefined: true }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_REQUEST:
      return { isLoading: true, allowedFeatures: [] }
    case actionTypes.FETCH_MY_PROFILE_SUCCESS:
      return Object.assign(action.payload, { isLoading: false, undefined: false })
    case actionTypes.FETCH_MY_PROFILE_FAILURE:
      return { isLoading: false, allowedFeatures: [], undefined: false }
  }
  return state
}

export function buildInfo(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_VERSION_INFO_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_VERSION_INFO_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_VERSION_INFO_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function subscriptionUsage(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTION_USAGE_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_SUBSCRIPTION_USAGE_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_SUBSCRIPTION_USAGE_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function orgConfiguration(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.ALL_ORG_CONFIG_RULES_REQUEST:
      return { isLoading: true }
    case actionTypes.ALL_ORG_CONFIG_RULES_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.ALL_ORG_CONFIG_RULES_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function uberIsDown(state = { isLoading: false, status: 'UNKNOWN' }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DOWN_UBER_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_DOWN_UBER_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_DOWN_UBER_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function rolesRevoked(state = { isLoading: false, status: 'UNKNOWN' }, action) {
  switch (action.type) {
    case actionTypes.ROLES_UPDATED_REQUEST:
      return { isLoading: true }
    case actionTypes.ROLES_UPDATED_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.ROLES_UPDATED_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function isBillingExpire(state = { isLoading: false, status: 'BILLED' }, action) {
  switch (action.type) {
    case actionTypes.BILLING_RENEWAL_CHECK_REQUEST:
      return { isLoading: true }
    case actionTypes.BILLING_RENEWAL_CHECK_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.BILLING_RENEWAL_CHECK_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function theme(state = { theme: true }, action) {
  switch (action.type) {
    case actionTypes.GET_THEME_REQUEST:
      return { theme: 'none' }

    case actionTypes.GET_THEME_SUCCESS:
      return { action }
    case actionTypes.GET_THEME_FAILURE:
      return { theme: 'none' }
  }
  return state
}

export function checkUberDeployment(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.SI_FE_UB_DEPLOYMENT_STATUS_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_UBER_DEPLOYMENT_STATUS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.SI_FE_UB_DEPLOYMENT_STATUS_SUCCESS:
    case actionTypes.FETCH_UBER_DEPLOYMENT_STATUS_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.SI_FE_UB_DEPLOYMENT_STATUS_REQUEST:
    case actionTypes.FETCH_UBER_DEPLOYMENT_STATUS_FAILURE:
      return Object.assign(action.payload.response, { isLoading: false })
  }
  return state
}

export function isSubscriptionCheck(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION_CHECK_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.GET_SUBSCRIPTION_CHECK_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.GET_SUBSCRIPTION_CHECK_FAILURE:
      return { ...state, isLoading: false }
  }
  return state
}

export function getAvailableUserRoles(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_ALL_USER_ROLES_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_ALL_USER_ROLES_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_ALL_USER_ROLES_FAILURE:
      return { ...state, isLoading: false }
  }
  return state
}

export function fetchAllgroups(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.GET_LIST_GROUPS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.GET_LIST_GROUPS_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.GET_LIST_GROUPS_FAILURE:
      return { ...state, isLoading: false }
  }
  return state
}

export function isAllowedDsSizeCheck(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_MAX_UPLOAD_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_DATASET_MAX_UPLOAD_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_DATASET_MAX_UPLOAD_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function myUserAccountInfo(state = { isLoading: false, userRoles: [] }, action) {
  switch (action.type) {
    case actionTypes.SILENTLY_FETCH_USER_ACCOUNT_INFO_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_USER_ACCOUNT_INFO_REQUEST:
      return { isLoading: true, userRoles: [] }
    case actionTypes.SILENTLY_FETCH_USER_ACCOUNT_INFO_SUCCESS:
    case actionTypes.FETCH_USER_ACCOUNT_INFO_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.SILENTLY_FETCH_USER_ACCOUNT_INFO_FAILURE:
    case actionTypes.FETCH_USER_ACCOUNT_INFO_FAILURE:
      return { isLoading: false, userRoles: [] }
  }
  return state
}

export function userProjectPermissions(state = { isLoading: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_PERMISSION_MATRIX_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_PERMISSION_MATRIX_SUCCESS:
      return Object.assign(action.payload.permissionMatrix, {
        isLoading: false,
        directPermissions: action.payload.directPermissions
      })
    case actionTypes.FETCH_PERMISSION_MATRIX_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function myHelpLinks(state = { isLoading: false, helpLinks: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_HELP_LINKS_REQUEST:
      return { isLoading: true, helpLinks: [] }
    case actionTypes.FETCH_HELP_LINKS_SUCCESS: {
      return Object.assign({ helpLinks: action.payload, isLoading: false })
    }
    case actionTypes.FETCH_HELP_LINKS_FAILURE:
      return { isLoading: false, helpLinks: [] }
  }
  return state
}

export function appStoreConfig(state = { isLoading: false, appLinks: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_APPSTORE_CONFIG_REQUEST:
      return { isLoading: true, appLinks: [] }
    case actionTypes.FETCH_APPSTORE_CONFIG_SUCCESS: {
      return Object.assign({ appLinks: action.payload, isLoading: false })
    }
    case actionTypes.FETCH_APPSTORE_CONFIG_FAILURE:
      return {
        isLoading: false, appLinks: {
          "crawler": {
          "Title": "Emly Crawler",
          "Description": "The Emly Crawler App is a no-code web crawling tool that helps users collect data from websites. Its easy-to-use interface allows anyone to set up crawls and extract useful information for analysis or AI projects.",
          "build_title": "Create Crawler App",
          "view_title": "View Crawler App",
          "sequence": 5,
          "icon": ""
          },
          "genAI": {
          "Title": "Emly Chatbot App(RAG)",
          "Description": "Description Emly ChatBot combines Generative AI and RAG to provide intelligent assistance for various tasks. It’s super easy to build and configure, delivering answer to user queries accurately.",
          "build_title": "Create Chatbot App",
          "view_title": "View Chatbot App",
          "sequence": 1,
          "icon": ""
          },
          "timeseries": {
          "Title": "Emly Automated Time Series App",
          "Description": "Choose this app to analyze and forecast trends over time. For instance, energy usage or predict sales patterns effectively with Emly Auto Timeseries, which automates time series analysis and helps you make informed decisions.",
          "build_title": "Create Time Series App",
          "view_title": "View Time Series App",
          "icon": "",
          "sequence": 4
          },
          "classifier": {
          "Title": "EMLY Classification App(AutoML)",
          "Description": "Emly AutoML Classification enables non-ML Experts to train high-quality models for their business challenges. Train your own custom classification model in minutes.",
          "build_title": "Create Classification App",
          "view_title": "View Classification App",
          "icon": "",
          "sequence": 3
          },
          "regression": {
          "Title": "EMLY Regression App(AutoML)",
          "Description": "Emly AutoML Regression enables non-ML Experts to train high-quality models for their business challenges. Train your own custom regression model in minutes.",
          "build_title": "Create Regression App",
          "view_title": "View Regression App",
          "sequence": 2,
          "icon": ""
          }
          }
      }
  }
  return state
}

export function datasetSchemas(state = { isLoading: false, datasetSchemas: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_SCHEMA_REQUEST: {
      state.datasetSchemas[action.meta.id] = { isLoading: true }
      return { ...state, datasetSchemas: state.datasetSchemas }
    }
    case actionTypes.FETCH_DATASET_SCHEMA_SUCCESS: {
      if (action.payload) {
        state.datasetSchemas[action.meta.id] = Object.assign(action.payload, {
          isLoading: false
        })
      }
      return { ...state, datasetSchemas: state.datasetSchemas }
    }
    case actionTypes.FETCH_DATASET_SCHEMA_FAILURE: {
      state.datasetSchemas[action.meta.id] = { isLoading: false }
      return { ...state, datasetSchemas: state.datasetSchemas }
    }
  }
  return state
}

export function itrSampleModel(state = { isLoading: false, iterations: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_ITR_SAMPLE_MODEL_REQUEST: {
      state.iterations[action.meta.iterId] = { isLoading: true }
      return { ...state, iterations: state.iterations }
    }
    case actionTypes.FETCH_ITR_SAMPLE_MODEL_SUCCESS: {
      state.iterations[action.meta.iterId] = Object.assign(action.payload, {
        isLoading: false
      })
      return { ...state, iterations: state.iterations }
    }
    case actionTypes.FETCH_ITR_SAMPLE_MODEL_FAILURE: {
      state.iterations[action.meta.iterId] = { isLoading: false }
      return { ...state, iterations: state.iterations }
    }
  }
  return state
}

export function datoinServices(state = { isLoading: false, studio: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_WELLKNOWN_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_WELLKNOWN_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_WELLKNOWN_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function appDetail(state = { isLoading: false, error: null, id: null, iterations: [] }, action) {
  switch (action.type) {
    case actionTypes.SILENTLY_FETCH_APP_DETAIL_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_APP_DETAIL_REQUEST:
      return { isLoading: true, error: null, id: null, iterations: [] }
    case actionTypes.SILENTLY_FETCH_APP_DETAIL_SUCCESS:
    case actionTypes.FETCH_APP_DETAIL_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.SILENTLY_FETCH_APP_DETAIL_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    case actionTypes.FETCH_APP_DETAIL_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function appItreationDetail(state = { isLoading: false, error: null, id: null, iterations: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_APP_ITERATION_DETAIL_SILENTLY_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_OPENLY_REQUEST:
      return { isLoading: true, error: null, id: null, iterations: [] }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_SILENTLY_SUCCESS:
    case actionTypes.FETCH_APP_ITERATION_DETAIL_OPENLY_SUCCESS:
      if (action.meta.append) {
        const previousIterations = state.iterations
        const iterations = previousIterations.concat(action.payload.iterations)
        return { ...action.payload, iterations: iterations, error: null, isLoading: false }
      }
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_SILENTLY_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_OPENLY_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function appDetailQuickSetting(state = { isLoading: false, error: null, id: null }, action) {
  switch (action.type) {
    case actionTypes.SILENTLY_FETCH_APP_QUICK_SETTING_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_APP_QUICK_SETTING_REQUEST:
      return { isLoading: true, error: null, id: null }
    case actionTypes.SILENTLY_FETCH_APP_QUICK_SETTING_SUCCESS:
    case actionTypes.FETCH_APP_QUICK_SETTING_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.SILENTLY_FETCH_APP_QUICK_SETTING_FAILURE:
    case actionTypes.FETCH_APP_QUICK_SETTING_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function myTemplates(
  state = {
    docs: [],
    pageInfo: {
      total: 0,
      max: 0,
      start: 0,
      nextStart: 0
    },
    start: 0,
    max: 20,
    total: 0,
    nextStart: 0,
    query: '',
    isLoading: true,
    tag: [],
    status: [],
    sort: 'updatedOn',
    sortOrder: 'asc'
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_TEMPLATES_REQUEST:
      return { ...state, isLoading: action.meta.append ? false : true }
    case actionTypes.FETCH_TEMPLATES_SUCCESS: {
      if (action.meta.append) {
        let previousDocs = state.docs
        return Object.assign(action.payload, {
          isLoading: false,
          docs: previousDocs.concat(action.payload.docs)
        })
      }
      return Object.assign(action.payload, { isLoading: false })
    }
    case actionTypes.FETCH_TEMPLATES_FAILURE:
      return { ...state, isLoading: action.meta.append ? false : true, error: action.payload }
  }
  return state
}

export function myModels(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_MODELS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_MY_MODELS_SUCCESS:
      return { ...action.payload, isLoading: false }
    case actionTypes.FETCH_MY_MODELS_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function myReccTemplates(
  state = {
    docs: [],
    pageInfo: {
      total: 0,
      max: 0,
      start: 0,
      nextStart: 0
    },
    isLoading: false
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_RECC_TEMPLATES_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_RECC_TEMPLATES_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_RECC_TEMPLATES_FAILURE:
      return { ...state, isLoading: false }
  }
  return state
}

export function myDatasets(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_DATASETS_REQUEST:
      return { ...state, error: null, isLoading: true }
    case actionTypes.FETCH_MY_DATASETS_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_MY_DATASETS_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function appSettingsRaw(state = { content: null, isLoading: true, isLarge: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_SETTINGS_CONTENT_REQUEST:
      return { ...state, content: null, isLoading: true }
    case actionTypes.FETCH_SETTINGS_CONTENT_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_SETTINGS_CONTENT_FAILURE:
      if (action.payload.status === 413) {
        return { ...state, isLoading: false, isLarge: true }
      } else {
        return { ...state, isLoading: false, error: action.payload }
      }
  }
  return state
}

export function appSettings(state = { resources: [], isLoading: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_APP_SETTINGS_REQUEST:
      return { ...state, resources: [], isLoading: true }
    case actionTypes.SILENTLY_FETCH_APP_SETTINGS_REQUEST:
      return { ...state, resources: [], isLoading: false }
    case actionTypes.FETCH_APP_SETTINGS_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.SILENTLY_FETCH_APP_SETTINGS_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_APP_SETTINGS_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    case actionTypes.SILENTLY_FETCH_APP_SETTINGS_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
  }
  return state
}

export function currentContext(state = { orgDetails: true }, action) {
  switch (action.type) {
    case actionTypes.GET_CURRENTCONTEXT_REQUEST:
      return { orgDetails: false }
    case actionTypes.GET_CURRENTCONTEXT_SUCCESS:
      return { ...action.orgDetails }
    case actionTypes.GET_CURRENTCONTEXT_FAILURE:
      return { orgDetails: false }
  }
  return state
}

export function specificOrgPracticeFlows(state = { isLoading: false,flows:[] }, action) {
  switch (action.type) {
    case actionTypes.GET_ORGPROJECT_FLOWS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.GET_ORGPROJECT_FLOWS_SUCCESS:
      return Object.assign({flows:action.payload},{ isLoading: false })
    case actionTypes.GET_ORGPROJECT_FLOWS_FAILURE:
      return { ...state, isLoading: false,flows:[]}
  }
  return state
}

export function allPracticeFlows( state = { isLoading: false ,flows:[] }, action) {
  switch (action.type) {
    case actionTypes.GET_PROJECT_FLOWS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.GET_PROJECT_FLOWS_SUCCESS:
      return Object.assign({flows:action.payload}, { isLoading: false })
    case actionTypes.GET_PROJECT_FLOWS_FAILURE:
      return { ...state, isLoading: false ,flows:[] }
  }
  return state
}