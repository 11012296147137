import actionTypes from './actionTypes.js'

export const pushProjects = payload => {
  return {
    type: 'GET_ALL_ORG_PROJECTS_SUCCESS',
    payload: payload
  }
}

export function getPushedProjects(state = { isLoading: false, projects: null }, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_ORG_PROJECTS_REQUEST:
      return { isLoading: false, projects: [] }
    case actionTypes.GET_ALL_ORG_PROJECTS_SUCCESS:
      return Object.assign({ projects: action.payload, isLoading: false })
    case actionTypes.GET_ALL_ORG_PROJECTS_FAILURE:
      return { isLoading: false, projects: [] }
  }
  return state
}
